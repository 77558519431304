.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left!important;
    margin-top: 10px;
}

.link {
    width: 80%;
}

.delete {
    display: flex;
    width: 20%;

    & > svg {
        margin: auto;
    }

    &:hover {
        cursor: pointer;
        color: var(--failColor);
    }
}

.disabled {
    display: none!important;
}

.currentFile {
    display: flex;
    position: relative;
    padding: 15px;
    background-color: #eee;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    border-radius: 4px;
    border: 2px dashed #bdbdbd;
    transition: all .3s ease-in;
    word-break: break-all;
    overflow: hidden;
}